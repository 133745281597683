import api from './api.config'
// import axios from 'axios'
export default new class organization {
  async list () {
    const res = await api.axios.get('/organization/contacts/list')
    return res
  }

  async create (data) {
    const res = await api.axios.post('/organization/contacts/create', { contacts: data })
    return res
  }

  async infos () {
    const res = await api.axios.get('/organization/infos')
    const data = res.data
    return data
  }

  async notifications () {
    const res = await api.axios.get('/notifications/metrics')
    return res
  }
}()
