import api from './api.config'
// import axios from 'axios'
export default new class Responsible {
  constructor () {
    this._list = null
  }

  async list (data) {
    try {
      this._listReq = await api.axios.post('users/listResponsible', {})
      this._list = this._listReq.data ? this._listReq.data : []
    } catch (err) {
      console.error(err)
    }

    return this._list || []
  }

  async verifyStudentRegistration (data) {
    const res = await api.axios.get(`users/students/${data}/verify/registration`)
    return res.data
  }

  async getStored (data) {
    if (this[`_${data}`]) {
      return this[`_${data}`]
    } else {
      return this.list()
    }
  }

  async getChildren (cpf) {
    const matriculas = (await api.axios.get(`parents/${cpf}/children/list`)).data
    return matriculas
  }

  async saveFiliation (parentCpf, childrenRegistrations) {
    await api.axios.post(`parents/${parentCpf}/children/assoc`, {
      children_registrations: childrenRegistrations
    })
  }

  async getParents (childRegistration) {
    const res = await api.axios.get('parents/', {
      params: {
        child_registration: childRegistration
      }
    })

    return res.data
  }
}()
