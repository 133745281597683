import api from './api.config'
import MediaUploader from './mediaUploader'

export default new class {
  upload (title, file, user) {
    const metadata = this.createResource({
      'snippet.categoryId': '22',
      'snippet.defaultLanguage': '',
      'snippet.description': 'Video uploaded using Dashboard v2' + ' by ' + user.email + ' - ' + user.name,
      'snippet.tags[]': '',
      'snippet.title': title,
      'status.embeddable': '',
      'status.license': '',
      'status.privacyStatus': 'unlisted',
      'status.publicStatsViewable': ''
    })

    const uploadPromise = new Promise(async (resolve, reject) => {
      const token = await this.getAccessToken()

      var params = {
        'part': 'snippet,status'
      }

      var uploader = new MediaUploader({
        baseUrl: 'https://www.googleapis.com/upload/youtube/v3/videos',
        file,
        token: token,
        metadata: metadata,
        params: params,
        onError: function (error) {
          reject(error)
        },
        onProgress: function (data) {
        },
        onComplete: async function (data) {
          var uploadResponse = JSON.parse(data)
          resolve(uploadResponse)
        }
      })

      if (file) {
        uploader.upload()
      }
    })

    return uploadPromise
  }

  createResource (properties) {
    var resource = {}
    var normalizedProps = properties
    for (var p in properties) {
      var value = properties[p]
      if (p && p.substr(-2, 2) === '[]') {
        var adjustedName = p.replace('[]', '')
        if (value) {
          normalizedProps[adjustedName] = value.split(',')
        }
        delete normalizedProps[p]
      }
    }
    for (let p in normalizedProps) {
      // Leave properties that don't have values out of inserted resource.
      if (normalizedProps.hasOwnProperty(p) && normalizedProps[p]) {
        var propArray = p.split('.')
        var ref = resource
        for (var pa = 0; pa < propArray.length; pa++) {
          var key = propArray[pa]
          if (pa === propArray.length - 1) {
            ref[key] = normalizedProps[p]
          } else {
            ref = ref[key] = ref[key] || {}
          }
        }
      };
    }
    return resource
  }

  async getAccessToken () {
    const token = (await api.axios.get('media/video/upload/token/get')).data.access_token
    return token
  }
}()
