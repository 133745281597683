import api from './api.config'
// import axios from 'axios'
export default new class OrganizationGroups {
  constructor () {
    this._list = null
    this._listAssocs = null
  }

  async list (data) {
    let params = {
      // search: [[]], // { field: 'posts.title', value: data }, { field: 'posts.body', value: data }
      // filter: [], // do mesmo jeito de search
      // last: '', // basta passar o valor como string
      // order: '' // "posts.id" (em ordem DESC) ou "posts.id,ASC" em ordem ASC
    }
    try {
      this._listReq = await api.axios.get('groups/list', { params: params })
      this._list = this._listReq.data ? this._listReq.data : []
    } catch (err) {
      console.error(err)
    }

    return this._list || []
  }

  async listAssocs (filter) {
    try {
      this._listReq = await api.axios.get('groups/assocs/list', { params: filter })
      this._listAssocs = this._listReq.data ? this._listReq.data : []
    } catch (err) {
      console.error(err)
    }

    return this._listAssocs
  }

  async listWithType () {
    var res = await api.axios.get('/groups/list').then((response) => {
      return response.data
    }).catch((err) => {
      console.error(err)
      return err.response.data.message
    })
    return res
  }

  async getStored (data) {
    if (this[`_${data}`]) {
      return this[`_${data}`]
    } else {
      return this.list()
    }
  }

  /**
   * @description Save groups associations with groups
   * @param {Object} user User
   * @param {Integer} user.id User id
   * @param {String} user.type User type
   * @param {String} user.matricula User matricula
   * @param {String} user.cpf User cpf
   * @param {Array} groups Groups to add user
   */
  async saveAssocs (user, groups) {
    const res = await api.axios.post('/groups/assocs/save', {
      user,
      groups
    })

    return res.data
  }

  /**
   * @description Add user to a group
   * @param {Object} user User
   * @param {Integer} user.id User id
   * @param {String} user.type User type
   * @param {String} user.matricula User matricula
   * @param {String} user.cpf User cpf
   * @param {String} user.role User's role in the group
   * @param {String} user.sync Whether to sync assoc or not
   * @param {Array} groupCod Group cod to add the user
   */
  async addAssocsToGroup (users, groupCod) {
    const res = await api.axios.post('/groups/assocs/users/add', {
      users,
      group_cod: groupCod
    })

    return res.data
  }

  /**
   * @description Remove user from a group/role
   * @param {Object} user User
   * @param {Integer} user.id User id to remove from group
   * @param {String} user.role User's role in the group
   * @param {Array} groupCod Cod of the group to remove the user from
   */
  async removeAssocsFromGroup (users, groupCod) {
    const res = await api.axios.post('/groups/assocs/users/remove', {
      users,
      group_cod: groupCod
    })

    return res.data
  }

  async listTypes () {
    const res = await api.axios.get('/groups/types/list')
    return res.data
  }

  async create (data) {
    const res = await api.axios.post('/groups/create', data)
    return res.data
  }
}()
