import api from './api.config'

export default new class {
  /**
 * @description Destroy connections
 * @param {Integer} userId User id to destroy connections
 * @param {Object[]} conns Connection object
 * @param {Object} conns[].type Connection type object
 * @param {String} conns[].type.value Connection type value
 * @param {Object} conns[].user User to destroy connection with
 */
  async destroyUsersConnections (userId, conns) {
    const res = await api.axios.post('/users/connections/remove', {
      user_id: userId,
      conns
    })
    return res.data
  }

  /**
 * @description Create connections
 * @param {Integer} userId User id to create connection
 * @param {Object[]} conns Connection object
 * @param {Object} conns[].type Connection type object
 * @param {String} conns[].type.value Connection type value
 * @param {Object} conns[].user User to create connection with
 */
  async createUsersConnections (userId, conns) {
    const res = await api.axios.post('/users/connections/create', {
      user_id: userId,
      conns
    })
    return res.data
  }
}()
