import api from './api.config'
// import axios from 'axios'

export default new class {
  // GET
  async getUserTypes () {
    const res = await api.axios.get('users/type/')
    return res.data
  }

  // POST
  async saveUserType (userTypeData) {
    return (await api.axios.post(`users/type/`, userTypeData)).data
  }

  // DELETE
  async deleteUserType (id) {
    return (await api.axios.delete(`users/type/${id}`)).data
  }

  // PUT
  async updateUserType (userTypeData, id) {
    return (await api.axios.put(`users/type/${id}`, userTypeData)).data
  }
}()
