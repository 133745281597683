import api from './api.config'
// import axios from 'axios'
export default new class Ouvidoria {
  constructor () {
    this._chatList = null
    this._messageList = null
  }

  async setLastMoment (data) {
    console.log('Set last Moment', data)
    try {
      this._req = await api.axios.put(`chat/conversations/${data.id}/setlastmoment`)
      return this._req.data
    } catch (err) {
      console.error(err)
    }

    return this._chatList || []
  }
  async listChats (data) {
    try {
      this._listReq = await api.axios.get('chat/conversations/list')
      this._chatList = this._listReq.data ? this._listReq.data : []
      // console.log('LISTA REQ', this._chatList)
    } catch (err) {
      console.error(err)
    }

    return this._chatList || []
  }

  async listConfig () {
    try {
      this._listReq = await api.axios.get('chat/config')
      this._configList = this._listReq.data ? this._listReq.data : []
    } catch (err) {
      console.error(err)
    }

    return this._configList || []
  }

  async addChatConfig (data) {
    let res = await api.axios.post(`/chat/config`, data)
    return res.data
  }

  async deleteChatConfig (chatConfig) {
    console.log(chatConfig.id)
    let res = await api.axios.delete(`/chat/config`, {
      data: {
        id: chatConfig.id
      }
    })
    return res
  }

  async listMessages (conversationId) {
    try {
      this._listReq = await api.axios.get(`chat/conversations/${conversationId}/messages/list`)
      this._messageList = this._listReq.data
    } catch (err) {
      console.error(err)
    }

    return this._messageList || []
  }

  async listCanTalk ({ search, offset }) {
    try {
      this._usersCanTalkReq = await api.axios.get('chat/conversations/allowedtotalk/list', {
        params: {
          search: search,
          offset: offset
        }
      })
      this._usersList = this._usersCanTalkReq.data
    } catch (err) {
      console.error(err)
    }

    return this._usersList || []
  }

  async createMessage (chat, message) {
    let data = {
      recipient_user_id: chat.id,
      type: 'from_school',
      text: message
    }
    let res = await api.axios.post(`/chat/conversations/${chat.id}/message/create`, data)
    return res
  }
  async getUnread () {
    try {
      const res = await api.axios.get('/chat/conversations/messages/unread')
      return res
    } catch (err) {
      console.error(err)
    }
  }

  /**
   *
   * @param {Members} members
   */
  async createRoom ({ users, groups }) {
    try {
      const members = { users, groups }
      const res = await api.axios.post(`/chat/conversations/room/create`, {
        members
      })
      return res.data
    } catch (err) {
      console.error(err)
    }
  }
  async getParticipants (id) {
    try {
      const response = await api.axios.get(`/chat/conversations/${id}/participants/list`)
      return response.data
    } catch (err) {
      console.error(err)
    }
  }
  async getStored (data) {
    if (this[`_${data}`]) {
      return this[`_${data}`]
    } else {
      return this.list()
    }
  }

  async createTitledRoom (assocs) {
    const res = await api.axios.post('/chat/conversations/room/createtitled', {
      assocs
    })

    return res.data
  }
}()
