import api from './api.config'
// import axios from 'axios'
export default new class grades {
  async list (data) {
    var res = await api.axios.get('/grades/list', { params: { matricula: data } })
    return res.data
  }
  async templateList (data) {
    var res = await api.axios.get('/grades/header/list', { params: { matricula: data } })
    return res.data
  }
}()
