/**
 *
 * This menu can be loaded from a webapi or database @beyond
 *
 */

import api from './api.config'
import store from '../store'
export default new class {
  constructor () {
    this.menu = null
    this.neverCalled = true
    this._FirstListPromise = new Promise((resolve, reject) => {
      this._solve = res => resolve(res)
      this._reject = res => reject(res)
    })
  }

  async get () {
    if (this.menu === null && this.neverCalled === true) {
      this.neverCalled = false
      try {
        let res = await api.axios.get('/menu/itens/list')
        store.commit('app/showDrawer', res.data.showDrawer)
        this._solve()
        this.menu = this.generateHeaders(res.data.items)
      } catch (err) {
        const res = err.response
        if (res.status === 401) {
          store.dispatch('auth/logout')
        }
      }
      // this.menu = this.generateHeaders(res.data)
    }
    await this._FirstListPromise
    return this.menu
  }

  generateHeaders (menuItens) {
    var newMenu = []
    var sectionMap = {}
    menuItens.map((item) => {
      if (item.section && item.section !== null && item.section.length > 0) {
        if (sectionMap[item.section]) {
          sectionMap[item.section].push(item)
        } else {
          sectionMap[item.section] = [item]
        }
      } else {
        newMenu.push(item)
      }
    })

    for (let section in sectionMap) {
      newMenu.push({ header: section })
      newMenu.push(...sectionMap[section])
    }
    return newMenu
  }
}()
