import api from './api.config'

export default new class {
  async createNotification (data) {
    try {
      const res = await api.axios.post('/notifications/create', data)
      return res
    } catch (err) {
      throw err
    }
  }
}()
