import api from './api.config'
// import axios from 'axios'
export default new class Presence {
  async create (presence) {
    var res = await api.axios.post('presence/create', presence)
    return res
  }

  async list (data) {
    var res = await api.axios.get('presence/list', { params: data })
    return res
  }

  async update (data) {
    var res = await api.axios.post('presence/update', data)
    return res
  }
}()
