const icons = [
  'mdi-access-point',
  'mdi-access-point-network',
  'mdi-account',
  'mdi-account-alert',
  'mdi-account-box',
  'mdi-account-box-outline',
  'mdi-account-card-details',
  'mdi-account-check',
  'mdi-account-circle',
  'mdi-account-convert',
  'mdi-account-edit',
  'mdi-account-key',
  'mdi-account-location',
  'mdi-account-minus',
  'mdi-account-multiple',
  'mdi-account-multiple-minus',
  'mdi-account-multiple-outline',
  'mdi-account-multiple-plus',
  'mdi-account-network',
  'mdi-account-o',
  'mdi-account-outline',
  'mdi-account-plus',
  'mdi-account-remove',
  'mdi-account-search',
  'mdi-account-settings',
  'mdi-account-settings-variant',
  'mdi-account-star',
  'mdi-account-switch',
  'mdi-adjust',
  'mdi-air-conditioner',
  'mdi-airballoon',
  'mdi-airplane',
  'mdi-airplane-landing',
  'mdi-airplane-o',
  'mdi-airplane-takeo',
  'mdi-airplay',
  'mdi-alarm',
  'mdi-alarm-bell',
  'mdi-alarm-check',
  'mdi-alarm-light',
  'mdi-alarm-multiple',
  'mdi-alarm-o',
  'mdi-alarm-plus',
  'mdi-alarm-snooze',
  'mdi-album',
  'mdi-alert',
  'mdi-alert-box',
  'mdi-alert-circle',
  'mdi-alert-circle-outline',
  'mdi-alert-decagram',
  'mdi-alert-octagon',
  'mdi-alert-octagram',
  'mdi-alert-outline',
  'mdi-all-inclusive',
  'mdi-alpha',
  'mdi-alphabetical',
  'mdi-altimeter',
  'mdi-amazon',
  'mdi-amazon-clouddrive',
  'mdi-ambulance',
  'mdi-ampliier',
  'mdi-anchor',
  'mdi-android',
  'mdi-android-debug-bridge',
  'mdi-android-head',
  'mdi-android-studio',
  'mdi-angular',
  'mdi-angularjs',
  'mdi-animation',
  'mdi-apple',
  'mdi-apple-inder',
  'mdi-apple-ios',
  'mdi-apple-keyboard-caps',
  'mdi-apple-keyboard-command',
  'mdi-apple-keyboard-control',
  'mdi-apple-keyboard-option',
  'mdi-apple-keyboard-shit',
  'mdi-apple-mobileme',
  'mdi-apple-saari',
  'mdi-application',
  'mdi-approval',
  'mdi-apps',
  'mdi-archive',
  'mdi-arrange-bring-orward',
  'mdi-arrange-bring-to-ront',
  'mdi-arrange-send-backward',
  'mdi-arrange-send-to-back',
  'mdi-arrow-all',
  'mdi-arrow-bottom-let',
  'mdi-arrow-bottom-right',
  'mdi-arrow-collapse',
  'mdi-arrow-collapse-all',
  'mdi-arrow-collapse-down',
  'mdi-arrow-collapse-let',
  'mdi-arrow-collapse-right',
  'mdi-arrow-collapse-up',
  'mdi-arrow-down',
  'mdi-arrow-down-bold',
  'mdi-arrow-down-bold-box',
  'mdi-arrow-down-bold-box-outline',
  'mdi-arrow-down-bold-circle',
  'mdi-arrow-down-bold-circle-outline',
  'mdi-arrow-down-bold-hexagon-outline',
  'mdi-arrow-down-box',
  'mdi-arrow-down-drop-circle',
  'mdi-arrow-down-drop-circle-outline',
  'mdi-arrow-down-thick',
  'mdi-arrow-expand',
  'mdi-arrow-expand-all',
  'mdi-arrow-expand-down',
  'mdi-arrow-expand-let',
  'mdi-arrow-expand-right',
  'mdi-arrow-expand-up',
  'mdi-arrow-let',
  'mdi-arrow-let-bold',
  'mdi-arrow-let-bold-box',
  'mdi-arrow-let-bold-box-outline',
  'mdi-arrow-let-bold-circle',
  'mdi-arrow-let-bold-circle-outline',
  'mdi-arrow-let-bold-hexagon-outline',
  'mdi-arrow-let-box',
  'mdi-arrow-let-drop-circle',
  'mdi-arrow-let-drop-circle-outline',
  'mdi-arrow-let-thick',
  'mdi-arrow-right',
  'mdi-arrow-right-bold',
  'mdi-arrow-right-bold-box',
  'mdi-arrow-right-bold-box-outline',
  'mdi-arrow-right-bold-circle',
  'mdi-arrow-right-bold-circle-outline',
  'mdi-arrow-right-bold-hexagon-outline',
  'mdi-arrow-right-box',
  'mdi-arrow-right-drop-circle',
  'mdi-arrow-right-drop-circle-outline',
  'mdi-arrow-right-thick',
  'mdi-arrow-top-let',
  'mdi-arrow-top-right',
  'mdi-arrow-up',
  'mdi-arrow-up-bold',
  'mdi-arrow-up-bold-box',
  'mdi-arrow-up-bold-box-outline',
  'mdi-arrow-up-bold-circle',
  'mdi-arrow-up-bold-circle-outline',
  'mdi-arrow-up-bold-hexagon-outline',
  'mdi-arrow-up-box',
  'mdi-arrow-up-drop-circle',
  'mdi-arrow-up-drop-circle-outline',
  'mdi-arrow-up-thick',
  'mdi-assistant',
  'mdi-asterisk',
  'mdi-at',
  'mdi-atom',
  'mdi-attachment',
  'mdi-audiobook',
  'mdi-auto-ix',
  'mdi-auto-upload',
  'mdi-autorenew',
  'mdi-av-timer',
  'mdi-baby',
  'mdi-baby-buggy',
  'mdi-backburger',
  'mdi-backspace',
  'mdi-backup-restore',
  'mdi-bandcamp',
  'mdi-bank',
  'mdi-barcode',
  'mdi-barcode-scan',
  'mdi-barley',
  'mdi-barrel',
  'mdi-basecamp',
  'mdi-basket',
  'mdi-basket-ill',
  'mdi-basket-unill',
  'mdi-battery',
  'mdi-battery-',
  'mdi-battery-',
  'mdi-battery-',
  'mdi-battery-',
  'mdi-battery-',
  'mdi-battery-',
  'mdi-battery-',
  'mdi-battery-',
  'mdi-battery-',
  'mdi-battery-alert',
  'mdi-battery-charging',
  'mdi-battery-charging-',
  'mdi-battery-charging-',
  'mdi-battery-charging-',
  'mdi-battery-charging-',
  'mdi-battery-charging-',
  'mdi-battery-charging-',
  'mdi-battery-charging-',
  'mdi-battery-minus',
  'mdi-battery-negative',
  'mdi-battery-outline',
  'mdi-battery-plus',
  'mdi-battery-positive',
  'mdi-battery-unknown',
  'mdi-beach',
  'mdi-beaker',
  'mdi-beats',
  'mdi-beer',
  'mdi-behance',
  'mdi-bell',
  'mdi-bell-o',
  'mdi-bell-outline',
  'mdi-bell-plus',
  'mdi-bell-ring',
  'mdi-bell-ring-outline',
  'mdi-bell-sleep',
  'mdi-beta',
  'mdi-bible',
  'mdi-bike',
  'mdi-bing',
  'mdi-binoculars',
  'mdi-bio',
  'mdi-biohazard',
  'mdi-bitbucket',
  'mdi-black-mesa',
  'mdi-blackberry',
  'mdi-blender',
  'mdi-blinds',
  'mdi-block-helper',
  'mdi-blogger',
  'mdi-bluetooth',
  'mdi-bluetooth-audio',
  'mdi-bluetooth-connect',
  'mdi-bluetooth-o',
  'mdi-bluetooth-settings',
  'mdi-bluetooth-transer',
  'mdi-blur',
  'mdi-blur-linear',
  'mdi-blur-o',
  'mdi-blur-radial',
  'mdi-bomb',
  'mdi-bomb-o',
  'mdi-bone',
  'mdi-book',
  'mdi-book-minus',
  'mdi-book-multiple',
  'mdi-book-multiple-variant',
  'mdi-book-open',
  'mdi-book-open-page-variant',
  'mdi-book-open-variant',
  'mdi-book-plus',
  'mdi-book-secure',
  'mdi-book-unsecure',
  'mdi-book-variant',
  'mdi-bookmark',
  'mdi-bookmark-check',
  'mdi-bookmark-music',
  'mdi-bookmark-outline',
  'mdi-bookmark-plus',
  'mdi-bookmark-plus-outline',
  'mdi-bookmark-remove',
  'mdi-boombox',
  'mdi-bootstrap',
  'mdi-border-all',
  'mdi-border-bottom',
  'mdi-border-color',
  'mdi-border-horizontal',
  'mdi-border-inside',
  'mdi-border-let',
  'mdi-border-none',
  'mdi-border-outside',
  'mdi-border-right',
  'mdi-border-style',
  'mdi-border-top',
  'mdi-border-vertical',
  'mdi-bow-tie',
  'mdi-bowl',
  'mdi-bowling',
  'mdi-box',
  'mdi-box-cutter',
  'mdi-box-shadow',
  'mdi-bridge',
  'mdi-briecase',
  'mdi-briecase-check',
  'mdi-briecase-download',
  'mdi-briecase-upload',
  'mdi-brightness-',
  'mdi-brightness-',
  'mdi-brightness-',
  'mdi-brightness-',
  'mdi-brightness-',
  'mdi-brightness-',
  'mdi-brightness-',
  'mdi-brightness-auto',
  'mdi-broom',
  'mdi-brush',
  'mdi-buer',
  'mdi-bug',
  'mdi-bulletin-board',
  'mdi-bullhorn',
  'mdi-bullseye',
  'mdi-burst-mode',
  'mdi-bus',
  'mdi-bus-articulated-end',
  'mdi-bus-articulated-ront',
  'mdi-bus-double-decker',
  'mdi-bus-school',
  'mdi-bus-side',
  'mdi-cached',
  'mdi-cake',
  'mdi-cake-layered',
  'mdi-cake-variant',
  'mdi-calculator',
  'mdi-calendar',
  'mdi-calendar-blank',
  'mdi-calendar-check',
  'mdi-calendar-clock',
  'mdi-calendar-multiple',
  'mdi-calendar-multiple-check',
  'mdi-calendar-plus',
  'mdi-calendar-question',
  'mdi-calendar-range',
  'mdi-calendar-remove',
  'mdi-calendar-text',
  'mdi-calendar-today',
  'mdi-call-made',
  'mdi-call-merge',
  'mdi-call-missed',
  'mdi-call-received',
  'mdi-call-split',
  'mdi-camcorder',
  'mdi-camcorder-box',
  'mdi-camcorder-box-o',
  'mdi-camcorder-o',
  'mdi-camera',
  'mdi-camera-burst',
  'mdi-camera-enhance',
  'mdi-camera-ront',
  'mdi-camera-ront-variant',
  'mdi-camera-gopro',
  'mdi-camera-iris',
  'mdi-camera-metering-center',
  'mdi-camera-metering-matrix',
  'mdi-camera-metering-partial',
  'mdi-camera-metering-spot',
  'mdi-camera-o',
  'mdi-camera-party-mode',
  'mdi-camera-rear',
  'mdi-camera-rear-variant',
  'mdi-camera-switch',
  'mdi-camera-timer',
  'mdi-cancel',
  'mdi-candle',
  'mdi-candycane',
  'mdi-cannabis',
  'mdi-car',
  'mdi-car-battery',
  'mdi-car-connected',
  'mdi-car-convertable',
  'mdi-car-estate',
  'mdi-car-hatchback',
  'mdi-car-pickup',
  'mdi-car-side',
  'mdi-car-sports',
  'mdi-car-wash',
  'mdi-caravan',
  'mdi-cards',
  'mdi-cards-outline',
  'mdi-cards-playing-outline',
  'mdi-cards-variant',
  'mdi-carrot',
  'mdi-cart',
  'mdi-cart-o',
  'mdi-cart-outline',
  'mdi-cart-plus',
  'mdi-case-sensitive-alt',
  'mdi-cash',
  'mdi-cash-',
  'mdi-cash-multiple',
  'mdi-cash-usd',
  'mdi-cast',
  'mdi-cast-connected',
  'mdi-cast-o',
  'mdi-castle',
  'mdi-cat',
  'mdi-cctv',
  'mdi-ceiling-light',
  'mdi-cellphone',
  'mdi-cellphone-android',
  'mdi-cellphone-basic',
  'mdi-cellphone-dock',
  'mdi-cellphone-iphone',
  'mdi-cellphone-link',
  'mdi-cellphone-link-o',
  'mdi-cellphone-settings',
  'mdi-certiicate',
  'mdi-chair-school',
  'mdi-chart-arc',
  'mdi-chart-areaspline',
  'mdi-chart-bar',
  'mdi-chart-bar-stacked',
  'mdi-chart-bubble',
  'mdi-chart-donut',
  'mdi-chart-donut-variant',
  'mdi-chart-gantt',
  'mdi-chart-histogram',
  'mdi-chart-line',
  'mdi-chart-line-stacked',
  'mdi-chart-line-variant',
  'mdi-chart-pie',
  'mdi-chart-scatterplot-hexbin',
  'mdi-chart-timeline',
  'mdi-check',
  'mdi-check-all',
  'mdi-check-circle',
  'mdi-check-circle-outline',
  'mdi-checkbox-blank',
  'mdi-checkbox-blank-circle',
  'mdi-checkbox-blank-circle-outline',
  'mdi-checkbox-blank-outline',
  'mdi-checkbox-marked',
  'mdi-checkbox-marked-circle',
  'mdi-checkbox-marked-circle-outline',
  'mdi-checkbox-marked-outline',
  'mdi-checkbox-multiple-blank',
  'mdi-checkbox-multiple-blank-circle',
  'mdi-checkbox-multiple-blank-circle-outline',
  'mdi-checkbox-multiple-blank-outline',
  'mdi-checkbox-multiple-marked',
  'mdi-checkbox-multiple-marked-circle',
  'mdi-checkbox-multiple-marked-circle-outline',
  'mdi-checkbox-multiple-marked-outline',
  'mdi-checkerboard',
  'mdi-chemical-weapon',
  'mdi-chevron-double-down',
  'mdi-chevron-double-let',
  'mdi-chevron-double-right',
  'mdi-chevron-double-up',
  'mdi-chevron-down',
  'mdi-chevron-let',
  'mdi-chevron-right',
  'mdi-chevron-up',
  'mdi-chili-hot',
  'mdi-chili-medium',
  'mdi-chili-mild',
  'mdi-chip',
  'mdi-church',
  'mdi-circle',
  'mdi-circle-outline',
  'mdi-cisco-webex',
  'mdi-city',
  'mdi-clipboard',
  'mdi-clipboard-account',
  'mdi-clipboard-alert',
  'mdi-clipboard-arrow-down',
  'mdi-clipboard-arrow-let',
  'mdi-clipboard-check',
  'mdi-clipboard-low',
  'mdi-clipboard-outline',
  'mdi-clipboard-plus',
  'mdi-clipboard-text',
  'mdi-clippy',
  'mdi-clock',
  'mdi-clock-alert',
  'mdi-clock-end',
  'mdi-clock-ast',
  'mdi-clock-in',
  'mdi-clock-out',
  'mdi-clock-start',
  'mdi-close',
  'mdi-close-box',
  'mdi-close-box-outline',
  'mdi-close-circle',
  'mdi-close-circle-outline',
  'mdi-close-network',
  'mdi-close-octagon',
  'mdi-close-octagon-outline',
  'mdi-close-outline',
  'mdi-closed-caption',
  'mdi-cloud',
  'mdi-cloud-braces',
  'mdi-cloud-check',
  'mdi-cloud-circle',
  'mdi-cloud-download',
  'mdi-cloud-o-outline',
  'mdi-cloud-outline',
  'mdi-cloud-print',
  'mdi-cloud-print-outline',
  'mdi-cloud-sync',
  'mdi-cloud-tags',
  'mdi-cloud-upload',
  'mdi-code-array',
  'mdi-code-braces',
  'mdi-code-brackets',
  'mdi-code-equal',
  'mdi-code-greater-than',
  'mdi-code-greater-than-or-equal',
  'mdi-code-less-than',
  'mdi-code-less-than-or-equal',
  'mdi-code-not-equal',
  'mdi-code-not-equal-variant',
  'mdi-code-parentheses',
  'mdi-code-string',
  'mdi-code-tags',
  'mdi-code-tags-check',
  'mdi-codepen',
  'mdi-coee',
  'mdi-coee-outline',
  'mdi-coee-to-go',
  'mdi-coin',
  'mdi-coins',
  'mdi-collage',
  'mdi-color-helper',
  'mdi-comment',
  'mdi-comment-account',
  'mdi-comment-account-outline',
  'mdi-comment-alert',
  'mdi-comment-alert-outline',
  'mdi-comment-check',
  'mdi-comment-check-outline',
  'mdi-comment-multiple-outline',
  'mdi-comment-outline',
  'mdi-comment-plus-outline',
  'mdi-comment-processing',
  'mdi-comment-processing-outline',
  'mdi-comment-question-outline',
  'mdi-comment-remove-outline',
  'mdi-comment-text',
  'mdi-comment-text-outline',
  'mdi-compare',
  'mdi-compass',
  'mdi-compass-outline',
  'mdi-console',
  'mdi-console-line',
  'mdi-contact-mail',
  'mdi-contacts',
  'mdi-content-copy',
  'mdi-content-cut',
  'mdi-content-duplicate',
  'mdi-content-paste',
  'mdi-content-save',
  'mdi-content-save-all',
  'mdi-content-save-settings',
  'mdi-contrast',
  'mdi-contrast-box',
  'mdi-contrast-circle',
  'mdi-cookie',
  'mdi-copyright',
  'mdi-corn',
  'mdi-counter',
  'mdi-cow',
  'mdi-creation',
  'mdi-credit-card',
  'mdi-credit-card-multiple',
  'mdi-credit-card-o',
  'mdi-credit-card-plus',
  'mdi-credit-card-scan',
  'mdi-crop',
  'mdi-crop-ree',
  'mdi-crop-landscape',
  'mdi-crop-portrait',
  'mdi-crop-rotate',
  'mdi-crop-square',
  'mdi-crosshairs',
  'mdi-crosshairs-gps',
  'mdi-crown',
  'mdi-cube',
  'mdi-cube-outline',
  'mdi-cube-send',
  'mdi-cube-unolded',
  'mdi-cup',
  'mdi-cup-o',
  'mdi-cup-water',
  'mdi-currency-btc',
  'mdi-currency-ch',
  'mdi-currency-cny',
  'mdi-currency-eth',
  'mdi-currency-eur',
  'mdi-currency-gbp',
  'mdi-currency-inr',
  'mdi-currency-jpy',
  'mdi-currency-krw',
  'mdi-currency-ngn',
  'mdi-currency-rub',
  'mdi-currency-sign',
  'mdi-currency-try',
  'mdi-currency-twd',
  'mdi-currency-usd',
  'mdi-currency-usd-o',
  'mdi-cursor-deault',
  'mdi-cursor-deault-outline',
  'mdi-cursor-move',
  'mdi-cursor-pointer',
  'mdi-cursor-text',
  'mdi-database',
  'mdi-database-minus',
  'mdi-database-plus',
  'mdi-debug-step-into',
  'mdi-debug-step-out',
  'mdi-debug-step-over',
  'mdi-decagram',
  'mdi-decagram-outline',
  'mdi-decimal-decrease',
  'mdi-decimal-increase',
  'mdi-delete',
  'mdi-delete-circle',
  'mdi-delete-empty',
  'mdi-delete-orever',
  'mdi-delete-sweep',
  'mdi-delete-variant',
  'mdi-delta',
  'mdi-deskphone',
  'mdi-desktop-classic',
  'mdi-desktop-mac',
  'mdi-desktop-tower',
  'mdi-details',
  'mdi-developer-board',
  'mdi-deviantart',
  'mdi-dialpad',
  'mdi-diamond',
  'mdi-dice-',
  'mdi-dice-',
  'mdi-dice-',
  'mdi-dice-',
  'mdi-dice-',
  'mdi-dice-',
  'mdi-dice-d',
  'mdi-dice-d',
  'mdi-dice-d',
  'mdi-dice-d',
  'mdi-dice-d',
  'mdi-dice-multiple',
  'mdi-dictionary',
  'mdi-dip-switch',
  'mdi-directions',
  'mdi-directions-ork',
  'mdi-discord',
  'mdi-disk',
  'mdi-disk-alert',
  'mdi-disqus',
  'mdi-disqus-outline',
  'mdi-division',
  'mdi-division-box',
  'mdi-dna',
  'mdi-dns',
  'mdi-do-not-disturb',
  'mdi-do-not-disturb-o',
  'mdi-dolby',
  'mdi-domain',
  'mdi-donkey',
  'mdi-dots-horizontal',
  'mdi-dots-horizontal-circle',
  'mdi-dots-vertical',
  'mdi-dots-vertical-circle',
  'mdi-douban',
  'mdi-download',
  'mdi-download-network',
  'mdi-drag',
  'mdi-drag-horizontal',
  'mdi-drag-vertical',
  'mdi-drawing',
  'mdi-drawing-box',
  'mdi-dribbble',
  'mdi-dribbble-box',
  'mdi-drone',
  'mdi-dropbox',
  'mdi-drupal',
  'mdi-duck',
  'mdi-dumbbell',
  'mdi-ear-hearing',
  'mdi-earth',
  'mdi-earth-box',
  'mdi-earth-box-o',
  'mdi-earth-o',
  'mdi-edge',
  'mdi-eject',
  'mdi-elephant',
  'mdi-elevation-decline',
  'mdi-elevation-rise',
  'mdi-elevator',
  'mdi-email',
  'mdi-email-alert',
  'mdi-email-open',
  'mdi-email-open-outline',
  'mdi-email-outline',
  'mdi-email-secure',
  'mdi-email-variant',
  'mdi-emby',
  'mdi-emoticon',
  'mdi-emoticon-cool',
  'mdi-emoticon-dead',
  'mdi-emoticon-devil',
  'mdi-emoticon-excited',
  'mdi-emoticon-happy',
  'mdi-emoticon-neutral',
  'mdi-emoticon-poop',
  'mdi-emoticon-sad',
  'mdi-emoticon-tongue',
  'mdi-engine',
  'mdi-engine-outline',
  'mdi-equal',
  'mdi-equal-box',
  'mdi-eraser',
  'mdi-eraser-variant',
  'mdi-escalator',
  'mdi-ethernet',
  'mdi-ethernet-cable',
  'mdi-ethernet-cable-o',
  'mdi-etsy',
  'mdi-ev-station',
  'mdi-eventbrite',
  'mdi-evernote',
  'mdi-exclamation',
  'mdi-exit-to-app',
  'mdi-export',
  'mdi-eye',
  'mdi-eye-o',
  'mdi-eye-o-outline',
  'mdi-eye-outline',
  'mdi-eyedropper',
  'mdi-eyedropper-variant',
  'mdi-ace',
  'mdi-ace-proile',
  'mdi-acebook',
  'mdi-acebook-box',
  'mdi-acebook-messenger',
  'mdi-actory',
  'mdi-an',
  'mdi-ast-orward',
  'mdi-ast-orward-outline',
  'mdi-ax',
  'mdi-eather',
  'mdi-erry',
  'mdi-ile',
  'mdi-ile-account',
  'mdi-ile-chart',
  'mdi-ile-check',
  'mdi-ile-cloud',
  'mdi-ile-delimited',
  'mdi-ile-document',
  'mdi-ile-document-box',
  'mdi-ile-excel',
  'mdi-ile-excel-box',
  'mdi-ile-export',
  'mdi-ile-ind',
  'mdi-ile-hidden',
  'mdi-ile-image',
  'mdi-ile-import',
  'mdi-ile-lock',
  'mdi-ile-multiple',
  'mdi-ile-music',
  'mdi-ile-outline',
  'mdi-ile-pd',
  'mdi-ile-pd-box',
  'mdi-ile-plus',
  'mdi-ile-powerpoint',
  'mdi-ile-powerpoint-box',
  'mdi-ile-presentation-box',
  'mdi-ile-restore',
  'mdi-ile-send',
  'mdi-ile-tree',
  'mdi-ile-video',
  'mdi-ile-word',
  'mdi-ile-word-box',
  'mdi-ile-xml',
  'mdi-ilm',
  'mdi-ilmstrip',
  'mdi-ilmstrip-o',
  'mdi-ilter',
  'mdi-ilter-outline',
  'mdi-ilter-remove',
  'mdi-ilter-remove-outline',
  'mdi-ilter-variant',
  'mdi-ind-replace',
  'mdi-ingerprint',
  'mdi-ire',
  'mdi-ireox',
  'mdi-ish',
  'mdi-lag',
  'mdi-lag-checkered',
  'mdi-lag-outline',
  'mdi-lag-outline-variant',
  'mdi-lag-triangle',
  'mdi-lag-variant',
  'mdi-lash',
  'mdi-lash-auto',
  'mdi-lash-o',
  'mdi-lash-outline',
  'mdi-lash-red-eye',
  'mdi-lashlight',
  'mdi-lashlight-o',
  'mdi-lask',
  'mdi-lask-empty',
  'mdi-lask-empty-outline',
  'mdi-lask-outline',
  'mdi-lattr',
  'mdi-lip-to-back',
  'mdi-lip-to-ront',
  'mdi-loppy',
  'mdi-lower',
  'mdi-older',
  'mdi-older-account',
  'mdi-older-download',
  'mdi-older-google-drive',
  'mdi-older-image',
  'mdi-older-lock',
  'mdi-older-lock-open',
  'mdi-older-move',
  'mdi-older-multiple',
  'mdi-older-multiple-image',
  'mdi-older-multiple-outline',
  'mdi-older-open',
  'mdi-older-outline',
  'mdi-older-plus',
  'mdi-older-remove',
  'mdi-older-star',
  'mdi-older-upload',
  'mdi-ont-awesome',
  'mdi-ood',
  'mdi-ood-apple',
  'mdi-ood-croissant',
  'mdi-ood-ork-drink',
  'mdi-ood-o',
  'mdi-ood-variant',
  'mdi-ootball',
  'mdi-ootball-australian',
  'mdi-ootball-helmet',
  'mdi-orklit',
  'mdi-ormat-align-bottom',
  'mdi-ormat-align-center',
  'mdi-ormat-align-justiy',
  'mdi-ormat-align-let',
  'mdi-ormat-align-middle',
  'mdi-ormat-align-right',
  'mdi-ormat-align-top',
  'mdi-ormat-annotation-plus',
  'mdi-ormat-bold',
  'mdi-ormat-clear',
  'mdi-ormat-color-ill',
  'mdi-ormat-color-text',
  'mdi-ormat-loat-center',
  'mdi-ormat-loat-let',
  'mdi-ormat-loat-none',
  'mdi-ormat-loat-right',
  'mdi-ormat-ont',
  'mdi-ormat-header-',
  'mdi-ormat-header-',
  'mdi-ormat-header-',
  'mdi-ormat-header-',
  'mdi-ormat-header-',
  'mdi-ormat-header-',
  'mdi-ormat-header-decrease',
  'mdi-ormat-header-equal',
  'mdi-ormat-header-increase',
  'mdi-ormat-header-pound',
  'mdi-ormat-horizontal-align-center',
  'mdi-ormat-horizontal-align-let',
  'mdi-ormat-horizontal-align-right',
  'mdi-ormat-indent-decrease',
  'mdi-ormat-indent-increase',
  'mdi-ormat-italic',
  'mdi-ormat-line-spacing',
  'mdi-ormat-line-style',
  'mdi-ormat-line-weight',
  'mdi-ormat-list-bulleted',
  'mdi-ormat-list-bulleted-type',
  'mdi-ormat-list-checks',
  'mdi-ormat-list-numbers',
  'mdi-ormat-page-break',
  'mdi-ormat-paint',
  'mdi-ormat-paragraph',
  'mdi-ormat-pilcrow',
  'mdi-ormat-quote-close',
  'mdi-ormat-quote-open',
  'mdi-ormat-rotate-',
  'mdi-ormat-section',
  'mdi-ormat-size',
  'mdi-ormat-strikethrough',
  'mdi-ormat-strikethrough-variant',
  'mdi-ormat-subscript',
  'mdi-ormat-superscript',
  'mdi-ormat-text',
  'mdi-ormat-textdirection-l-to-r',
  'mdi-ormat-textdirection-r-to-l',
  'mdi-ormat-title',
  'mdi-ormat-underline',
  'mdi-ormat-vertical-align-bottom',
  'mdi-ormat-vertical-align-center',
  'mdi-ormat-vertical-align-top',
  'mdi-ormat-wrap-inline',
  'mdi-ormat-wrap-square',
  'mdi-ormat-wrap-tight',
  'mdi-ormat-wrap-top-bottom',
  'mdi-orum',
  'mdi-orward',
  'mdi-oursquare',
  'mdi-ridge',
  'mdi-ridge-illed',
  'mdi-ridge-illed-bottom',
  'mdi-ridge-illed-top',
  'mdi-uel',
  'mdi-ullscreen',
  'mdi-ullscreen-exit',
  'mdi-unction',
  'mdi-gamepad',
  'mdi-gamepad-variant',
  'mdi-garage',
  'mdi-garage-open',
  'mdi-gas-cylinder',
  'mdi-gas-station',
  'mdi-gate',
  'mdi-gauge',
  'mdi-gavel',
  'mdi-gender-emale',
  'mdi-gender-male',
  'mdi-gender-male-emale',
  'mdi-gender-transgender',
  'mdi-gesture',
  'mdi-gesture-double-tap',
  'mdi-gesture-swipe-down',
  'mdi-gesture-swipe-let',
  'mdi-gesture-swipe-right',
  'mdi-gesture-swipe-up',
  'mdi-gesture-tap',
  'mdi-gesture-two-double-tap',
  'mdi-gesture-two-tap',
  'mdi-ghost',
  'mdi-git',
  'mdi-git',
  'mdi-github-box',
  'mdi-github-circle',
  'mdi-github-ace',
  'mdi-glass-lute',
  'mdi-glass-mug',
  'mdi-glass-stange',
  'mdi-glass-tulip',
  'mdi-glassdoor',
  'mdi-glasses',
  'mdi-gmail',
  'mdi-gnome',
  'mdi-gondola',
  'mdi-google',
  'mdi-google-analytics',
  'mdi-google-assistant',
  'mdi-google-cardboard',
  'mdi-google-chrome',
  'mdi-google-circles',
  'mdi-google-circles-communities',
  'mdi-google-circles-extended',
  'mdi-google-circles-group',
  'mdi-google-controller',
  'mdi-google-controller-o',
  'mdi-google-drive',
  'mdi-google-earth',
  'mdi-google-glass',
  'mdi-google-keep',
  'mdi-google-maps',
  'mdi-google-nearby',
  'mdi-google-pages',
  'mdi-google-photos',
  'mdi-google-physical-web',
  'mdi-google-play',
  'mdi-google-plus',
  'mdi-google-plus-box',
  'mdi-google-translate',
  'mdi-google-wallet',
  'mdi-gradient',
  'mdi-grease-pencil',
  'mdi-grid',
  'mdi-grid-large',
  'mdi-grid-o',
  'mdi-group',
  'mdi-guitar-acoustic',
  'mdi-guitar-electric',
  'mdi-guitar-pick',
  'mdi-guitar-pick-outline',
  'mdi-hackernews',
  'mdi-hamburger',
  'mdi-hand-pointing-right',
  'mdi-hanger',
  'mdi-hangouts',
  'mdi-harddisk',
  'mdi-headphones',
  'mdi-headphones-box',
  'mdi-headphones-o',
  'mdi-headphones-settings',
  'mdi-headset',
  'mdi-headset-dock',
  'mdi-headset-o',
  'mdi-heart',
  'mdi-heart-box',
  'mdi-heart-box-outline',
  'mdi-heart-broken',
  'mdi-heart-hal',
  'mdi-heart-hal-ull',
  'mdi-heart-hal-outline',
  'mdi-heart-o',
  'mdi-heart-outline',
  'mdi-heart-pulse',
  'mdi-help',
  'mdi-help-box',
  'mdi-help-circle',
  'mdi-help-circle-outline',
  'mdi-help-network',
  'mdi-hexagon',
  'mdi-hexagon-multiple',
  'mdi-hexagon-outline',
  'mdi-high-deinition',
  'mdi-highway',
  'mdi-history',
  'mdi-hololens',
  'mdi-home',
  'mdi-home-assistant',
  'mdi-home-automation',
  'mdi-home-circle',
  'mdi-home-map-marker',
  'mdi-home-modern',
  'mdi-home-outline',
  'mdi-home-variant',
  'mdi-hook',
  'mdi-hook-o',
  'mdi-hops',
  'mdi-hospital',
  'mdi-hospital-building',
  'mdi-hospital-marker',
  'mdi-hotel',
  'mdi-houzz',
  'mdi-houzz-box',
  'mdi-human',
  'mdi-human-child',
  'mdi-human-emale',
  'mdi-human-greeting',
  'mdi-human-handsdown',
  'mdi-human-handsup',
  'mdi-human-male',
  'mdi-human-male-emale',
  'mdi-human-pregnant',
  'mdi-humble-bundle',
  'mdi-image',
  'mdi-image-album',
  'mdi-image-area',
  'mdi-image-area-close',
  'mdi-image-broken',
  'mdi-image-broken-variant',
  'mdi-image-ilter',
  'mdi-image-ilter-black-white',
  'mdi-image-ilter-center-ocus',
  'mdi-image-ilter-center-ocus-weak',
  'mdi-image-ilter-drama',
  'mdi-image-ilter-rames',
  'mdi-image-ilter-hdr',
  'mdi-image-ilter-none',
  'mdi-image-ilter-tilt-shit',
  'mdi-image-ilter-vintage',
  'mdi-image-multiple',
  'mdi-import',
  'mdi-inbox',
  'mdi-inbox-arrow-down',
  'mdi-inbox-arrow-up',
  'mdi-incognito',
  'mdi-ininity',
  'mdi-inormation',
  'mdi-inormation-outline',
  'mdi-inormation-variant',
  'mdi-instagram',
  'mdi-instapaper',
  'mdi-internet-explorer',
  'mdi-invert-colors',
  'mdi-itunes',
  'mdi-jeepney',
  'mdi-jira',
  'mdi-jsiddle',
  'mdi-json',
  'mdi-keg',
  'mdi-kettle',
  'mdi-key',
  'mdi-key-change',
  'mdi-key-minus',
  'mdi-key-plus',
  'mdi-key-remove',
  'mdi-key-variant',
  'mdi-keyboard',
  'mdi-keyboard-backspace',
  'mdi-keyboard-caps',
  'mdi-keyboard-close',
  'mdi-keyboard-o',
  'mdi-keyboard-return',
  'mdi-keyboard-tab',
  'mdi-keyboard-variant',
  'mdi-kickstarter',
  'mdi-kodi',
  'mdi-label',
  'mdi-label-outline',
  'mdi-lambda',
  'mdi-lamp',
  'mdi-lan',
  'mdi-lan-connect',
  'mdi-lan-disconnect',
  'mdi-lan-pending',
  'mdi-language-c',
  'mdi-language-cpp',
  'mdi-language-csharp',
  'mdi-language-css',
  'mdi-language-go',
  'mdi-language-html',
  'mdi-language-javascript',
  'mdi-language-php',
  'mdi-language-python',
  'mdi-language-python-text',
  'mdi-language-r',
  'mdi-language-swit',
  'mdi-language-typescript',
  'mdi-laptop',
  'mdi-laptop-chromebook',
  'mdi-laptop-mac',
  'mdi-laptop-o',
  'mdi-laptop-windows',
  'mdi-lastm',
  'mdi-launch',
  'mdi-lava-lamp',
  'mdi-layers',
  'mdi-layers-o',
  'mdi-lead-pencil',
  'mdi-lea',
  'mdi-led-o',
  'mdi-led-on',
  'mdi-led-outline',
  'mdi-led-strip',
  'mdi-led-variant-o',
  'mdi-led-variant-on',
  'mdi-led-variant-outline',
  'mdi-library',
  'mdi-library-books',
  'mdi-library-music',
  'mdi-library-plus',
  'mdi-lightbulb',
  'mdi-lightbulb-on',
  'mdi-lightbulb-on-outline',
  'mdi-lightbulb-outline',
  'mdi-link',
  'mdi-link-o',
  'mdi-link-variant',
  'mdi-link-variant-o',
  'mdi-linkedin',
  'mdi-linkedin-box',
  'mdi-linux',
  'mdi-loading',
  'mdi-lock',
  'mdi-lock-open',
  'mdi-lock-open-outline',
  'mdi-lock-outline',
  'mdi-lock-pattern',
  'mdi-lock-plus',
  'mdi-lock-reset',
  'mdi-locker',
  'mdi-locker-multiple',
  'mdi-login',
  'mdi-login-variant',
  'mdi-logout',
  'mdi-logout-variant',
  'mdi-looks',
  'mdi-loop',
  'mdi-loupe',
  'mdi-lumx',
  'mdi-magnet',
  'mdi-magnet-on',
  'mdi-magniy',
  'mdi-magniy-minus',
  'mdi-magniy-minus-outline',
  'mdi-magniy-plus',
  'mdi-magniy-plus-outline',
  'mdi-mail-ru',
  'mdi-mailbox',
  'mdi-map',
  'mdi-map-marker',
  'mdi-map-marker-circle',
  'mdi-map-marker-minus',
  'mdi-map-marker-multiple',
  'mdi-map-marker-o',
  'mdi-map-marker-outline',
  'mdi-map-marker-plus',
  'mdi-map-marker-radius',
  'mdi-margin',
  'mdi-markdown',
  'mdi-marker',
  'mdi-marker-check',
  'mdi-martini',
  'mdi-material-ui',
  'mdi-math-compass',
  'mdi-matrix',
  'mdi-maxcdn',
  'mdi-medical-bag',
  'mdi-medium',
  'mdi-memory',
  'mdi-menu',
  'mdi-menu-down',
  'mdi-menu-down-outline',
  'mdi-menu-let',
  'mdi-menu-right',
  'mdi-menu-up',
  'mdi-menu-up-outline',
  'mdi-message',
  'mdi-message-alert',
  'mdi-message-bulleted',
  'mdi-message-bulleted-o',
  'mdi-message-draw',
  'mdi-message-image',
  'mdi-message-outline',
  'mdi-message-plus',
  'mdi-message-processing',
  'mdi-message-reply',
  'mdi-message-reply-text',
  'mdi-message-settings',
  'mdi-message-settings-variant',
  'mdi-message-text',
  'mdi-message-text-outline',
  'mdi-message-video',
  'mdi-meteor',
  'mdi-metronome',
  'mdi-metronome-tick',
  'mdi-micro-sd',
  'mdi-microphone',
  'mdi-microphone-o',
  'mdi-microphone-outline',
  'mdi-microphone-settings',
  'mdi-microphone-variant',
  'mdi-microphone-variant-o',
  'mdi-microscope',
  'mdi-microsot',
  'mdi-minecrat',
  'mdi-minus',
  'mdi-minus-box',
  'mdi-minus-box-outline',
  'mdi-minus-circle',
  'mdi-minus-circle-outline',
  'mdi-minus-network',
  'mdi-mixcloud',
  'mdi-mixer',
  'mdi-monitor',
  'mdi-monitor-multiple',
  'mdi-more',
  'mdi-motorbike',
  'mdi-mouse',
  'mdi-mouse-o',
  'mdi-mouse-variant',
  'mdi-mouse-variant-o',
  'mdi-move-resize',
  'mdi-move-resize-variant',
  'mdi-movie',
  'mdi-movie-roll',
  'mdi-multiplication',
  'mdi-multiplication-box',
  'mdi-mushroom',
  'mdi-mushroom-outline',
  'mdi-music',
  'mdi-music-box',
  'mdi-music-box-outline',
  'mdi-music-circle',
  'mdi-music-note',
  'mdi-music-note-bluetooth',
  'mdi-music-note-bluetooth-o',
  'mdi-music-note-eighth',
  'mdi-music-note-hal',
  'mdi-music-note-o',
  'mdi-music-note-quarter',
  'mdi-music-note-sixteenth',
  'mdi-music-note-whole',
  'mdi-music-o',
  'mdi-nature',
  'mdi-nature-people',
  'mdi-navigation',
  'mdi-near-me',
  'mdi-needle',
  'mdi-nest-protect',
  'mdi-nest-thermostat',
  'mdi-netlix',
  'mdi-network',
  'mdi-new-box',
  'mdi-newspaper',
  'mdi-nc',
  'mdi-nc-tap',
  'mdi-nc-variant',
  'mdi-ninja',
  'mdi-nintendo-switch',
  'mdi-nodejs',
  'mdi-note',
  'mdi-note-multiple',
  'mdi-note-multiple-outline',
  'mdi-note-outline',
  'mdi-note-plus',
  'mdi-note-plus-outline',
  'mdi-note-text',
  'mdi-notiication-clear-all',
  'mdi-npm',
  'mdi-nuke',
  'mdi-null',
  'mdi-numeric',
  'mdi-numeric--box',
  'mdi-numeric--box-multiple-outline',
  'mdi-numeric--box-outline',
  'mdi-numeric--box',
  'mdi-numeric--box-multiple-outline',
  'mdi-numeric--box-outline',
  'mdi-numeric--box',
  'mdi-numeric--box-multiple-outline',
  'mdi-numeric--box-outline',
  'mdi-numeric--box',
  'mdi-numeric--box-multiple-outline',
  'mdi-numeric--box-outline',
  'mdi-numeric--box',
  'mdi-numeric--box-multiple-outline',
  'mdi-numeric--box-outline',
  'mdi-numeric--box',
  'mdi-numeric--box-multiple-outline',
  'mdi-numeric--box-outline',
  'mdi-numeric--box',
  'mdi-numeric--box-multiple-outline',
  'mdi-numeric--box-outline',
  'mdi-numeric--box',
  'mdi-numeric--box-multiple-outline',
  'mdi-numeric--box-outline',
  'mdi-numeric--box',
  'mdi-numeric--box-multiple-outline',
  'mdi-numeric--box-outline',
  'mdi-numeric--box',
  'mdi-numeric--box-multiple-outline',
  'mdi-numeric--box-outline',
  'mdi-numeric--plus-box',
  'mdi-numeric--plus-box-multiple-outline',
  'mdi-numeric--plus-box-outline',
  'mdi-nut',
  'mdi-nutrition',
  'mdi-oar',
  'mdi-octagon',
  'mdi-octagon-outline',
  'mdi-octagram',
  'mdi-octagram-outline',
  'mdi-odnoklassniki',
  'mdi-oice',
  'mdi-oil',
  'mdi-oil-temperature',
  'mdi-omega',
  'mdi-onedrive',
  'mdi-onenote',
  'mdi-opacity',
  'mdi-open-in-app',
  'mdi-open-in-new',
  'mdi-openid',
  'mdi-opera',
  'mdi-orbit',
  'mdi-ornament',
  'mdi-ornament-variant',
  'mdi-owl',
  'mdi-package',
  'mdi-package-down',
  'mdi-package-up',
  'mdi-package-variant',
  'mdi-package-variant-closed',
  'mdi-page-irst',
  'mdi-page-last',
  'mdi-page-layout-body',
  'mdi-page-layout-ooter',
  'mdi-page-layout-header',
  'mdi-page-layout-sidebar-let',
  'mdi-page-layout-sidebar-right',
  'mdi-palette',
  'mdi-palette-advanced',
  'mdi-panda',
  'mdi-pandora',
  'mdi-panorama',
  'mdi-panorama-isheye',
  'mdi-panorama-horizontal',
  'mdi-panorama-vertical',
  'mdi-panorama-wide-angle',
  'mdi-paper-cut-vertical',
  'mdi-paperclip',
  'mdi-parking',
  'mdi-passport',
  'mdi-pause',
  'mdi-pause-circle',
  'mdi-pause-circle-outline',
  'mdi-pause-octagon',
  'mdi-pause-octagon-outline',
  'mdi-paw',
  'mdi-paw-o',
  'mdi-pen',
  'mdi-pencil',
  'mdi-pencil-box',
  'mdi-pencil-box-outline',
  'mdi-pencil-circle',
  'mdi-pencil-circle-outline',
  'mdi-pencil-lock',
  'mdi-pencil-o',
  'mdi-pentagon',
  'mdi-pentagon-outline',
  'mdi-percent',
  'mdi-periodic-table-co',
  'mdi-periscope',
  'mdi-pharmacy',
  'mdi-phone',
  'mdi-phone-bluetooth',
  'mdi-phone-classic',
  'mdi-phone-orward',
  'mdi-phone-hangup',
  'mdi-phone-in-talk',
  'mdi-phone-incoming',
  'mdi-phone-locked',
  'mdi-phone-log',
  'mdi-phone-minus',
  'mdi-phone-missed',
  'mdi-phone-outgoing',
  'mdi-phone-paused',
  'mdi-phone-plus',
  'mdi-phone-settings',
  'mdi-phone-voip',
  'mdi-pi',
  'mdi-pi-box',
  'mdi-piano',
  'mdi-pig',
  'mdi-pill',
  'mdi-pillar',
  'mdi-pin',
  'mdi-pin-o',
  'mdi-pine-tree',
  'mdi-pine-tree-box',
  'mdi-pinterest',
  'mdi-pinterest-box',
  'mdi-pipe',
  'mdi-pipe-disconnected',
  'mdi-pistol',
  'mdi-pizza',
  'mdi-plane-shield',
  'mdi-play',
  'mdi-play-box-outline',
  'mdi-play-circle',
  'mdi-play-circle-outline',
  'mdi-play-pause',
  'mdi-play-protected-content',
  'mdi-playlist-check',
  'mdi-playlist-minus',
  'mdi-playlist-play',
  'mdi-playlist-plus',
  'mdi-playlist-remove',
  'mdi-playstation',
  'mdi-plex',
  'mdi-plus',
  'mdi-plus-box',
  'mdi-plus-box-outline',
  'mdi-plus-circle',
  'mdi-plus-circle-multiple-outline',
  'mdi-plus-circle-outline',
  'mdi-plus-network',
  'mdi-plus-one',
  'mdi-plus-outline',
  'mdi-pocket',
  'mdi-pokeball',
  'mdi-polaroid',
  'mdi-poll',
  'mdi-poll-box',
  'mdi-polymer',
  'mdi-pool',
  'mdi-popcorn',
  'mdi-pot',
  'mdi-pot-mix',
  'mdi-pound',
  'mdi-pound-box',
  'mdi-power',
  'mdi-power-plug',
  'mdi-power-plug-o',
  'mdi-power-settings',
  'mdi-power-socket',
  'mdi-power-socket-eu',
  'mdi-power-socket-uk',
  'mdi-power-socket-us',
  'mdi-prescription',
  'mdi-presentation',
  'mdi-presentation-play',
  'mdi-printer',
  'mdi-printer-d',
  'mdi-printer-alert',
  'mdi-printer-settings',
  'mdi-priority-high',
  'mdi-priority-low',
  'mdi-proessional-hexagon',
  'mdi-projector',
  'mdi-projector-screen',
  'mdi-publish',
  'mdi-pulse',
  'mdi-puzzle',
  'mdi-qqchat',
  'mdi-qrcode',
  'mdi-qrcode-scan',
  'mdi-quadcopter',
  'mdi-quality-high',
  'mdi-quicktime',
  'mdi-radar',
  'mdi-radiator',
  'mdi-radio',
  'mdi-radio-handheld',
  'mdi-radio-tower',
  'mdi-radioactive',
  'mdi-radiobox-blank',
  'mdi-radiobox-marked',
  'mdi-raspberrypi',
  'mdi-ray-end',
  'mdi-ray-end-arrow',
  'mdi-ray-start',
  'mdi-ray-start-arrow',
  'mdi-ray-start-end',
  'mdi-ray-vertex',
  'mdi-rdio',
  'mdi-react',
  'mdi-read',
  'mdi-readability',
  'mdi-receipt',
  'mdi-record',
  'mdi-record-rec',
  'mdi-recycle',
  'mdi-reddit',
  'mdi-redo',
  'mdi-redo-variant',
  'mdi-reresh',
  'mdi-regex',
  'mdi-relative-scale',
  'mdi-reload',
  'mdi-remote',
  'mdi-rename-box',
  'mdi-reorder-horizontal',
  'mdi-reorder-vertical',
  'mdi-repeat',
  'mdi-repeat-o',
  'mdi-repeat-once',
  'mdi-replay',
  'mdi-reply',
  'mdi-reply-all',
  'mdi-reproduction',
  'mdi-resize-bottom-right',
  'mdi-responsive',
  'mdi-restart',
  'mdi-restore',
  'mdi-rewind',
  'mdi-rewind-outline',
  'mdi-rhombus',
  'mdi-rhombus-outline',
  'mdi-ribbon',
  'mdi-rice',
  'mdi-ring',
  'mdi-road',
  'mdi-road-variant',
  'mdi-robot',
  'mdi-rocket',
  'mdi-roomba',
  'mdi-rotate-d',
  'mdi-rotate-let',
  'mdi-rotate-let-variant',
  'mdi-rotate-right',
  'mdi-rotate-right-variant',
  'mdi-rounded-corner',
  'mdi-router-wireless',
  'mdi-routes',
  'mdi-rowing',
  'mdi-rss',
  'mdi-rss-box',
  'mdi-ruler',
  'mdi-run',
  'mdi-run-ast',
  'mdi-sale',
  'mdi-sass',
  'mdi-satellite',
  'mdi-satellite-variant',
  'mdi-saxophone',
  'mdi-scale',
  'mdi-scale-balance',
  'mdi-scale-bathroom',
  'mdi-scanner',
  'mdi-school',
  'mdi-screen-rotation',
  'mdi-screen-rotation-lock',
  'mdi-screwdriver',
  'mdi-script',
  'mdi-sd',
  'mdi-seal',
  'mdi-search-web',
  'mdi-seat-lat',
  'mdi-seat-lat-angled',
  'mdi-seat-individual-suite',
  'mdi-seat-legroom-extra',
  'mdi-seat-legroom-normal',
  'mdi-seat-legroom-reduced',
  'mdi-seat-recline-extra',
  'mdi-seat-recline-normal',
  'mdi-security',
  'mdi-security-home',
  'mdi-security-network',
  'mdi-select',
  'mdi-select-all',
  'mdi-select-inverse',
  'mdi-select-o',
  'mdi-selection',
  'mdi-selection-o',
  'mdi-send',
  'mdi-send-secure',
  'mdi-serial-port',
  'mdi-server',
  'mdi-server-minus',
  'mdi-server-network',
  'mdi-server-network-o',
  'mdi-server-o',
  'mdi-server-plus',
  'mdi-server-remove',
  'mdi-server-security',
  'mdi-set-all',
  'mdi-set-center',
  'mdi-set-center-right',
  'mdi-set-let',
  'mdi-set-let-center',
  'mdi-set-let-right',
  'mdi-set-none',
  'mdi-set-right',
  'mdi-settings',
  'mdi-settings-box',
  'mdi-shape-circle-plus',
  'mdi-shape-plus',
  'mdi-shape-polygon-plus',
  'mdi-shape-rectangle-plus',
  'mdi-shape-square-plus',
  'mdi-share',
  'mdi-share-variant',
  'mdi-shield',
  'mdi-shield-hal-ull',
  'mdi-shield-outline',
  'mdi-shopping',
  'mdi-shopping-music',
  'mdi-shovel',
  'mdi-shovel-o',
  'mdi-shredder',
  'mdi-shule',
  'mdi-shule-disabled',
  'mdi-shule-variant',
  'mdi-sigma',
  'mdi-sigma-lower',
  'mdi-sign-caution',
  'mdi-sign-direction',
  'mdi-sign-text',
  'mdi-signal',
  'mdi-signal-g',
  'mdi-signal-g',
  'mdi-signal-g',
  'mdi-signal-hspa',
  'mdi-signal-hspa-plus',
  'mdi-signal-o',
  'mdi-signal-variant',
  'mdi-silverware',
  'mdi-silverware-ork',
  'mdi-silverware-spoon',
  'mdi-silverware-variant',
  'mdi-sim',
  'mdi-sim-alert',
  'mdi-sim-o',
  'mdi-sitemap',
  'mdi-skip-backward',
  'mdi-skip-orward',
  'mdi-skip-next',
  'mdi-skip-next-circle',
  'mdi-skip-next-circle-outline',
  'mdi-skip-previous',
  'mdi-skip-previous-circle',
  'mdi-skip-previous-circle-outline',
  'mdi-skull',
  'mdi-skype',
  'mdi-skype-business',
  'mdi-slack',
  'mdi-sleep',
  'mdi-sleep-o',
  'mdi-smoking',
  'mdi-smoking-o',
  'mdi-snapchat',
  'mdi-snowlake',
  'mdi-snowman',
  'mdi-soccer',
  'mdi-soa',
  'mdi-solid',
  'mdi-sort',
  'mdi-sort-alphabetical',
  'mdi-sort-ascending',
  'mdi-sort-descending',
  'mdi-sort-numeric',
  'mdi-sort-variant',
  'mdi-soundcloud',
  'mdi-source-branch',
  'mdi-source-commit',
  'mdi-source-commit-end',
  'mdi-source-commit-end-local',
  'mdi-source-commit-local',
  'mdi-source-commit-next-local',
  'mdi-source-commit-start',
  'mdi-source-commit-start-next-local',
  'mdi-source-ork',
  'mdi-source-merge',
  'mdi-source-pull',
  'mdi-soy-sauce',
  'mdi-speaker',
  'mdi-speaker-o',
  'mdi-speaker-wireless',
  'mdi-speedometer',
  'mdi-spellcheck',
  'mdi-spotiy',
  'mdi-spotlight',
  'mdi-spotlight-beam',
  'mdi-spray',
  'mdi-square',
  'mdi-square-inc',
  'mdi-square-inc-cash',
  'mdi-square-outline',
  'mdi-square-root',
  'mdi-stackexchange',
  'mdi-stackoverlow',
  'mdi-stadium',
  'mdi-stairs',
  'mdi-standard-deinition',
  'mdi-star',
  'mdi-star-circle',
  'mdi-star-hal',
  'mdi-star-o',
  'mdi-star-outline',
  'mdi-steam',
  'mdi-steering',
  'mdi-step-backward',
  'mdi-step-backward-',
  'mdi-step-orward',
  'mdi-step-orward-',
  'mdi-stethoscope',
  'mdi-sticker',
  'mdi-sticker-emoji',
  'mdi-stocking',
  'mdi-stop',
  'mdi-stop-circle',
  'mdi-stop-circle-outline',
  'mdi-store',
  'mdi-store--hour',
  'mdi-stove',
  'mdi-subdirectory-arrow-let',
  'mdi-subdirectory-arrow-right',
  'mdi-subway',
  'mdi-subway-variant',
  'mdi-summit',
  'mdi-sunglasses',
  'mdi-surround-sound',
  'mdi-surround-sound--',
  'mdi-surround-sound--',
  'mdi-surround-sound--',
  'mdi-surround-sound--',
  'mdi-svg',
  'mdi-swap-horizontal',
  'mdi-swap-vertical',
  'mdi-swim',
  'mdi-switch',
  'mdi-sword',
  'mdi-sword-cross',
  'mdi-sync',
  'mdi-sync-alert',
  'mdi-sync-o',
  'mdi-tab',
  'mdi-tab-plus',
  'mdi-tab-unselected',
  'mdi-table',
  'mdi-table-column-plus-ater',
  'mdi-table-column-plus-beore',
  'mdi-table-column-remove',
  'mdi-table-column-width',
  'mdi-table-edit',
  'mdi-table-large',
  'mdi-table-row-height',
  'mdi-table-row-plus-ater',
  'mdi-table-row-plus-beore',
  'mdi-table-row-remove',
  'mdi-tablet',
  'mdi-tablet-android',
  'mdi-tablet-ipad',
  'mdi-taco',
  'mdi-tag',
  'mdi-tag-aces',
  'mdi-tag-heart',
  'mdi-tag-multiple',
  'mdi-tag-outline',
  'mdi-tag-plus',
  'mdi-tag-remove',
  'mdi-tag-text-outline',
  'mdi-target',
  'mdi-taxi',
  'mdi-teamviewer',
  'mdi-telegram',
  'mdi-television',
  'mdi-television-classic',
  'mdi-television-guide',
  'mdi-temperature-celsius',
  'mdi-temperature-ahrenheit',
  'mdi-temperature-kelvin',
  'mdi-tennis',
  'mdi-tent',
  'mdi-terrain',
  'mdi-test-tube',
  'mdi-text-shadow',
  'mdi-text-to-speech',
  'mdi-text-to-speech-o',
  'mdi-textbox',
  'mdi-textbox-password',
  'mdi-texture',
  'mdi-theater',
  'mdi-theme-light-dark',
  'mdi-thermometer',
  'mdi-thermometer-lines',
  'mdi-thought-bubble',
  'mdi-thought-bubble-outline',
  'mdi-thumb-down',
  'mdi-thumb-down-outline',
  'mdi-thumb-up',
  'mdi-thumb-up-outline',
  'mdi-thumbs-up-down',
  'mdi-ticket',
  'mdi-ticket-account',
  'mdi-ticket-conirmation',
  'mdi-ticket-percent',
  'mdi-tie',
  'mdi-tilde',
  'mdi-timelapse',
  'mdi-timer',
  'mdi-timer-',
  'mdi-timer-',
  'mdi-timer-o',
  'mdi-timer-sand',
  'mdi-timer-sand-empty',
  'mdi-timer-sand-ull',
  'mdi-timetable',
  'mdi-toggle-switch',
  'mdi-toggle-switch-o',
  'mdi-tooltip',
  'mdi-tooltip-edit',
  'mdi-tooltip-image',
  'mdi-tooltip-outline',
  'mdi-tooltip-outline-plus',
  'mdi-tooltip-text',
  'mdi-tooth',
  'mdi-tor',
  'mdi-tower-beach',
  'mdi-tower-ire',
  'mdi-trackpad',
  'mdi-traic-light',
  'mdi-train',
  'mdi-tram',
  'mdi-transcribe',
  'mdi-transcribe-close',
  'mdi-transer',
  'mdi-transit-transer',
  'mdi-translate',
  'mdi-treasure-chest',
  'mdi-tree',
  'mdi-trello',
  'mdi-trending-down',
  'mdi-trending-neutral',
  'mdi-trending-up',
  'mdi-triangle',
  'mdi-triangle-outline',
  'mdi-trophy',
  'mdi-trophy-award',
  'mdi-trophy-outline',
  'mdi-trophy-variant',
  'mdi-trophy-variant-outline',
  'mdi-truck',
  'mdi-truck-delivery',
  'mdi-truck-ast',
  'mdi-truck-trailer',
  'mdi-tshirt-crew',
  'mdi-tshirt-v',
  'mdi-tumblr',
  'mdi-tumblr-reblog',
  'mdi-tune',
  'mdi-tune-vertical',
  'mdi-twitch',
  'mdi-twitter',
  'mdi-twitter-box',
  'mdi-twitter-circle',
  'mdi-twitter-retweet',
  'mdi-uber',
  'mdi-ubuntu',
  'mdi-ultra-high-deinition',
  'mdi-umbraco',
  'mdi-umbrella',
  'mdi-umbrella-outline',
  'mdi-undo',
  'mdi-undo-variant',
  'mdi-unold-less-horizontal',
  'mdi-unold-less-vertical',
  'mdi-unold-more-horizontal',
  'mdi-unold-more-vertical',
  'mdi-ungroup',
  'mdi-unity',
  'mdi-untappd',
  'mdi-update',
  'mdi-upload',
  'mdi-upload-network',
  'mdi-usb',
  'mdi-van-passenger',
  'mdi-van-utility',
  'mdi-vanish',
  'mdi-vector-arrange-above',
  'mdi-vector-arrange-below',
  'mdi-vector-circle',
  'mdi-vector-circle-variant',
  'mdi-vector-combine',
  'mdi-vector-curve',
  'mdi-vector-dierence',
  'mdi-vector-dierence-ab',
  'mdi-vector-dierence-ba',
  'mdi-vector-intersection',
  'mdi-vector-line',
  'mdi-vector-point',
  'mdi-vector-polygon',
  'mdi-vector-polyline',
  'mdi-vector-radius',
  'mdi-vector-rectangle',
  'mdi-vector-selection',
  'mdi-vector-square',
  'mdi-vector-triangle',
  'mdi-vector-union',
  'mdi-veriied',
  'mdi-vibrate',
  'mdi-video',
  'mdi-video-d',
  'mdi-video-o',
  'mdi-video-switch',
  'mdi-view-agenda',
  'mdi-view-array',
  'mdi-view-carousel',
  'mdi-view-column',
  'mdi-view-dashboard',
  'mdi-view-day',
  'mdi-view-grid',
  'mdi-view-headline',
  'mdi-view-list',
  'mdi-view-module',
  'mdi-view-parallel',
  'mdi-view-quilt',
  'mdi-view-sequential',
  'mdi-view-stream',
  'mdi-view-week',
  'mdi-vimeo',
  'mdi-vine',
  'mdi-violin',
  'mdi-visualstudio',
  'mdi-vk',
  'mdi-vk-box',
  'mdi-vk-circle',
  'mdi-vlc',
  'mdi-voice',
  'mdi-voicemail',
  'mdi-volume-high',
  'mdi-volume-low',
  'mdi-volume-medium',
  'mdi-volume-minus',
  'mdi-volume-mute',
  'mdi-volume-o',
  'mdi-volume-plus',
  'mdi-vpn',
  'mdi-walk',
  'mdi-wall',
  'mdi-wallet',
  'mdi-wallet-gitcard',
  'mdi-wallet-membership',
  'mdi-wallet-travel',
  'mdi-wan',
  'mdi-washing-machine',
  'mdi-watch',
  'mdi-watch-export',
  'mdi-watch-import',
  'mdi-watch-vibrate',
  'mdi-water',
  'mdi-water-o',
  'mdi-water-percent',
  'mdi-water-pump',
  'mdi-watermark',
  'mdi-waves',
  'mdi-weather-cloudy',
  'mdi-weather-og',
  'mdi-weather-hail',
  'mdi-weather-lightning',
  'mdi-weather-lightning-rainy',
  'mdi-weather-night',
  'mdi-weather-partlycloudy',
  'mdi-weather-pouring',
  'mdi-weather-rainy',
  'mdi-weather-snowy',
  'mdi-weather-snowy-rainy',
  'mdi-weather-sunny',
  'mdi-weather-sunset',
  'mdi-weather-sunset-down',
  'mdi-weather-sunset-up',
  'mdi-weather-windy',
  'mdi-weather-windy-variant',
  'mdi-web',
  'mdi-webcam',
  'mdi-webhook',
  'mdi-webpack',
  'mdi-wechat',
  'mdi-weight',
  'mdi-weight-kilogram',
  'mdi-whatsapp',
  'mdi-wheelchair-accessibility',
  'mdi-white-balance-auto',
  'mdi-white-balance-incandescent',
  'mdi-white-balance-iridescent',
  'mdi-white-balance-sunny',
  'mdi-widgets',
  'mdi-wii',
  'mdi-wii-o',
  'mdi-wii',
  'mdi-wiiu',
  'mdi-wikipedia',
  'mdi-window-close',
  'mdi-window-closed',
  'mdi-window-maximize',
  'mdi-window-minimize',
  'mdi-window-open',
  'mdi-window-restore',
  'mdi-windows',
  'mdi-wordpress',
  'mdi-worker',
  'mdi-wrap',
  'mdi-wrench',
  'mdi-wunderlist',
  'mdi-xaml',
  'mdi-xbox',
  'mdi-xbox-controller',
  'mdi-xbox-controller-battery-alert',
  'mdi-xbox-controller-battery-empty',
  'mdi-xbox-controller-battery-ull',
  'mdi-xbox-controller-battery-low',
  'mdi-xbox-controller-battery-medium',
  'mdi-xbox-controller-battery-unknown',
  'mdi-xbox-controller-o',
  'mdi-xda',
  'mdi-xing',
  'mdi-xing-box',
  'mdi-xing-circle',
  'mdi-xml',
  'mdi-xmpp',
  'mdi-yammer',
  'mdi-yeast',
  'mdi-yelp',
  'mdi-yin-yang',
  'mdi-youtube-play',
  'mdi-zip-box',
  'mdi-blank'

]
export default icons
