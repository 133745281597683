import api from './api.config'
// import axios from 'axios'

export default new class {
  // GET
  async getMenuItems () {
    const res = await api.axios.get('menu/itens/')
    return res.data
  }

  // POST
  async saveMenuItem (menuItemData) {
    return (await api.axios.post(`menu/itens/`, menuItemData)).data
  }

  // DELETE
  async deleteMenuItem (id) {
    return (await api.axios.delete(`menu/itens/${id}`)).data
  }

  // PUT
  async updateMenuItem (menuItemData, id) {
    return (await api.axios.put(`menu/itens/${id}`, menuItemData)).data
  }
}()
