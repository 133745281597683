import api from './api.config'

export default new class {
  async getToken (userId) {
    const token = (await api.axios.get('/auth/user/token', {
      params: {
        userId
      }
    })).data

    return token
  }
}()
