import api from './api.config'
// import axios from 'axios'

export default new class User {
  constructor () {
    this._list = null
  }

  async getUser (userId) {
    const res = (await api.axios.get(`/users/show/${userId}`)).data
    return res
  }

  async getMe () {
    const me = (await api.axios.get('/users/me/show')).data
    return me
  }

  async list (queryParams) {
    this._list = (await api.list('users/list', queryParams)).data

    return this._list
    // params.filter[0].push([{mutuallyExclusive: [{field: 'posts.type', value: queryParams.pending ? }]}])
  }

  async verifyUser (data) {
    let res = await api.axios.get('auth/users/verify/alreadyregistered', { params: { institutionSlug: data.institutionSlug, login_method_slug: data.login_method_slug, user: data.user } })
    return res
  }

  async verifyLastName (data) {
    let res = await api.axios.get('auth/users/verify/lastname', { params: { institutionSlug: data.institutionSlug, login_method_slug: data.login_method_slug, user: data.user, firstName: data.firstName, lastName: data.lastName } })
    return res
  }

  async login (data) {
    let res = await api.axios.post('auth/login', { user: data.user })
    return res
  }

  async getMethodUserTypes () {
    let deviceName

    if (window.hasOwnProperty('cordova')) {
      deviceName = window.cordova.platformId
    } else {
      deviceName = 'web'
    }

    let res = await api.axios.get('/auth/login/methods', {
      params: {
        app_version: process.env.VUE_APP_APP_CODE_VERSION || 0,
        device_name: deviceName
      }
    })

    return res
  }
  async getUserType (types, next, offset, search) {
    let res = (await api.list('users/list', {
      types,
      next,
      offset,
      search
    })).data
    return res
  }
  async registerUser (user) {
    let res = await api.axios.post('/auth/user/register/firstaccess', { user: user })
    return res
  }

  async saveUser (userData) {
    const res = (await api.axios.post('/users/save', userData)).data
    return res
  }

  async getEmailTip (institutionSlug, user) {
    var res = await api.axios.get('/auth/password/' + user + '/mailtip').then((response) => {
      return response.data
    }).catch((err) => {
      console.error(err)
      return err.response.data.message
    })
    return res
  }

  async createNewPassword (post) {
    var res = await api.axios.post('/auth/password/reset', post).then((response) => {
      return response
    }).catch((err) => {
      console.error(err)
      return err.response
    })
    return res
  }

  async recoverPassword (institutionSlug, user, email) {
    var res = await api.axios.get('/auth/password/' + user + '/' + email + '/recover').then(() => {
      return true
    }).catch((err) => {
      console.error(err)
      return false
    })
    return res
  }

  async getStored (data) {
    if (this[`_${data}`]) {
      return this[`_${data}`]
    } else {
      return this.list()
    }
  }

  async saveDevice (device) {
    await api.axios.post(`/users/device/save`, device)
  }

  async listTypes () {
    const res = await api.axios.get('/users/types/list')
    return res.data
  }

  async listProfiles () {
    const res = await api.axios.get('/users/profiles/list')
    return res.data
  }

  async removeDevice (token) {
    // const token = notification.getToken()
    const platform = window.cordova ? window.cordova.platformId : 'web'

    console.log('removeDevice', 'token:', token, 'platform:', platform)

    if (typeof token === 'string' && typeof platform === 'string') {
      await api.axios.post('/users/device/remove', {
        token,
        platform
      })
      return true
    }

    return false
  }

  async listGroupsAssocs (userId) {
    const res = await api.axios.get('/users/groups/assocs/list', {
      params: {
        user_id: userId
      }
    })

    return res.data
  }

  async createGroupsAssocs (userId, assocs) {
    const res = await api.axios.post('/users/groups/assocs/create', {
      user_id: userId,
      assocs
    })

    return res.data
  }

  async destroyGroupsAssocs (userId, assocs) {
    const res = await api.axios.post('/users/groups/assocs/destroy', {
      user_id: userId,
      assocs
    })

    return res.data
  }

  async getUserPermissions (userId) {
    const res = await api.axios.get(`/users/permissions`, {
      params: {
        user_id: userId
      }
    })

    return res.data.permissions
  }

  async updateProfileImage (url) {
    const res = await api.axios.put('/users/profile/image/update', {
      profile_image_url: url
    })

    return res.data
  }
}()
