import axios from 'axios'
import store from '../store/index'
export default new class Config {
  constructor () {
    this.axios = null
    this._token = null
  }

  async setup (institution) {
    this._token = store.getters['auth/token']
    console.log(process.env.VUE_APP_API_BASE_URL)
    console.log('######################')
    this.axios = axios.create({
      baseURL: `${process.env.VUE_APP_API_BASE_URL}${institution}`,
      headers: {
        // When there's no authentication, don't set a token
        'Authorization': store.getters['auth/authenticated'] ? `Bearer ${this._token}` : undefined
      }
    })
  }

  /**
   *
   * @description use this function to list a resource of API Node.JS with Query parameters..
   *
   * @param {string} resource
   * @param {object} search
   * @param {object} filter
   * @param {string} order
   * @param {string} last
   */
  // list (resource, search, filter, order, last) {
  list (resource, queryParams) {
    // let data = {
    //   search: [[]], // { field: 'posts.title', value: data }, { field: 'posts.body', value: data }
    //   filter: [], // do mesmo jeito de search
    //   last: '', // basta passar o valor como string
    //   order: '' // "posts.id" (em ordem DESC) ou "posts.id,ASC" em ordem ASC
    // }
    if (this.axios) {
      return this.axios.get(resource,
        { params: queryParams }
      )
    }
  }

  _list (search, filter, order, last) {
    /* return {
      search: [
        [
          { field: 'posts.title', value: '' },
          { field: 'posts.body', value: '' }
        ]
      ],
      // (a=1 AND b=2 AND a=3)
      // ((a=1 OR a=2) and a=3)
      filter: [
        [
          { multuallyExclusive: [{ field: 'posts.title', value: '' }, { field: 'posts.body', value: '' }] },
          { multuallyExclusive: [{ field: 'posts.date', value: '' }, { field: 'posts.date2', value: '' }] },
          { field: 'a', value: '1' },
          { field: 'b', value: '2' }
        ]
      ]
    } */
  }
}()
