// import api from './api.config'
import axios from 'axios'
export default new class Responsible {
  constructor () {
    this._list = null
  }

  async list () {
    return axios.get(`${process.env.VUE_APP_API_BASE_URL}institutions/list`)
  }
}()
