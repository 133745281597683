import api from './api.config'
// import axios from 'axios'

export default new class {
  // GET
  async getPermissions () {
    const res = await api.axios.get('permissions/')
    return res.data
  }

  // POST
  async savePermission (permissionData) {
    return (await api.axios.post(`permissions/`, permissionData)).data
  }

  // DELETE
  async deletePermission (id) {
    return (await api.axios.delete(`permissions/${id}`)).data
  }

  // PUT
  async updatePermission (permissionData, id) {
    return (await api.axios.put(`permissions/${id}`, permissionData)).data
  }
}()
