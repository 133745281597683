import api from './api.config'
// import axios from 'axios'
export default new class diary {
  async listRecipientsGroups (data) {
    const res = await api.axios.get('/posts/assocs/class/' + data.categoryId + '/' + data.date)
    return res.data
  }
  async listRecipientsUsers (data) {
    const res = await api.axios.get('/posts/assocs/users/' + data.categoryId + '/' + data.date)
    return res.data
  }
  async listGroupPosts (data) {
    var res = await api.axios.get('/posts/assocs/' + data.groupCod + '/' + data.categoryId + '/' + data.date)
    return res.data
  }
}()
