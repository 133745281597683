import config from './api.config'
import store from '../store'

export default new class Post {
  constructor () {
    this._list = null
  }

  async recipients (status, postId) {
    try {
      this._lastParams = {}
      this._recipientsReq = await config.axios.get(`posts/${postId}/recipients/${status}`)
      this._recipients = this._recipientsReq.data ? this._recipientsReq.data : []
    } catch (err) {
      console.error(err)
    }
    return this._recipients
  }

  async allow (post) {
    try {
      await config.axios.put(`posts/${post.id}/allow`)
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  async read (tasks) {
    const res = await config.axios.put(`posts/read`, {
      tasks
    })

    return res.data
  }

  async deny (post) {
    try {
      await config.axios.put(`posts/${post.id}/deny`)
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  async delete (post) {
    try {
      await config.axios.delete(`posts/${post.id}`)
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  // async getResumeRecipient (id) {
  //   let res = await config.axios.get(`posts/assocs/classes/get/${id}`)
  //   return res.data
  // }

  async getResumeRecipients (posts) {
    let res = await config.axios.get(`posts/assocs/classes/getarray/`, {
      params: {
        postIdArray: posts.map(r => r.id).join(',')
      }
    })
    return res.data
  }
  async getModels () {
    var res = await config.axios.get('/models/list')
    return res
  }

  async editPost (post) {
    let res = await config.axios.put(`/posts/${post.id}`, post)
    return res
  }

  async createPost (post) {
    let res = await config.axios.post('/posts/create', post)
    return res
  }

  async getCategories () {
    var res = await config.axios.get('/posts/categories/list')
    return res
  }
  async createCategory (params) {
    var res = await config.axios.post('/posts/categories/create', params)
    return res
  }
  async deleteCategory (params) {
    var res = await config.axios.post('/posts/categories/delete', params)
    return res
  }
  async updateCategory (params) {
    var res = await config.axios.post('/posts/categories/update', params)
    return res
  }
  async listLastPosts (userId) {
    var res = await config.axios.get(`/users/${userId}/details`)
    return res.data
  }

  async list (queryParams) {
    let params = {
      search: [],
      mutuallyExclusive: 'true',
      filterEnsino: queryParams.filterEnsino,
      filter: [[]], // do mesmo jeito de search
      last: queryParams.last,
      order: 'posts.id,DESC' // "posts.id" (em ordem DESC) ou "posts.id,ASC" em ordem ASC
    }

    if (queryParams.search && queryParams.search.length > 0) {
      params.search.push([{ field: 'posts.title', value: queryParams.search }, { field: 'posts.body', value: queryParams.search }])
    }

    let postsFilters = {
      // Isso fará com que caso não seja selecionado algum tipo válido, ele vai tentar trazer os posts do tipo '' que não retornará nada.
      mutuallyExclusive: [ { field: 'posts.type', value: '' } ]
    }

    if (queryParams.pending === true || queryParams.onlyPending === true) {
      postsFilters.mutuallyExclusive.push({ field: 'posts.type', value: 'post_pending' })
    } else {
      postsFilters.mutuallyExclusive.push({ field: 'posts.type', value: 'post_pending' })
      postsFilters.mutuallyExclusive.push({ field: 'posts.type', value: 'post' })
    }

    // if (queryParams.notApproved === true) {
    //   postsFilters.mutuallyExclusive.push({ field: 'posts.type', value: 'draft' })
    // }
    // if (queryParams.alreadyPublished === true && queryParams.scheduled === true) {
    //   postsFilters.mutuallyExclusive.push({ field: 'posts.type', value: 'post' })
    // }

    if (queryParams.selectedAssocUsers && queryParams.selectedGroups) {
      const filter = []
      for (let cod of queryParams.selectedGroups) {
        filter.push(...[{ field: 'pa.group_cod', value: cod.toString() }])
      }
      for (let cod of queryParams.selectedAssocUsers) {
        if (cod && (typeof cod === typeof 0 || (typeof cod === typeof '' && cod.length > 0))) {
          filter.push(...[{ field: 'pa.group_cod', value: cod.toString() }])
        }
      }
      params.filter[0].push({ mutuallyExclusive: filter })
    }

    if (postsFilters.mutuallyExclusive.length > 0) {
      params.filter[0].push(postsFilters)
    } else {
      params.filter = [[]]
    }

    if (queryParams.selectedGroups && !queryParams.selectedAssocUsers) {
      for (let cod of queryParams.selectedGroups) {
        params.filter[0].push(...[{ field: 'pa.group_type', value: 'turma' }, { field: 'pa.group_cod', value: cod.toString() }])
      }
    }

    if (queryParams.selectedAssocUsers && !queryParams.selectedGroups) {
      for (let cod of queryParams.selectedAssocUsers) {
        if (cod && (typeof cod === typeof 0 || (typeof cod === typeof '' && cod.length > 0))) {
          params.filter[0].push(...[{ field: 'pa.group_type', value: 'user' }, { field: 'pa.group_cod', value: cod.toString() }])
        }
      }
    }

    if (queryParams.postId) {
      params.filter[0].push(...[{ field: 'posts.id', value: queryParams.postId + '' }])
    }
    if (queryParams.categoryId) {
      params.filter[0].push(...[{ field: 'posts.category_id', value: queryParams.categoryId + '' }])
    }

    if (queryParams.recipientId) {
      params.filter[0].push(...[{ field: 'ps.user_id', value: queryParams.recipientId + '' }])
    }

    if (queryParams.onlyPending === true) {
      params.filter[0].push(...[{ field: 'posts.type', value: 'post_pending' }])
    }

    // if (queryParams.filterEnsino && queryParams.filterEnsino.length > 0) {
    //   params.filter[0].push(...[{ field: 'posts.type', value: queryParams.filterEnsino }])
    // }

    if (queryParams.selectedUsers) {
      if (typeof queryParams.selectedUsers === typeof '' || typeof queryParams.selectedUsers === typeof 0) {
        params.filter[0].push(...[{ field: 'creator_user.id', value: queryParams.selectedUsers.toString() }])
      } else if (typeof queryParams.selectedUsers === typeof []) {
        for (let cod of queryParams.selectedUsers) {
          params.filter[0].push(...[{ field: 'creator_user.id', value: cod }])
        }
      }
    }

    const stringifyParams = JSON.stringify(params)
    if (this._lastParams !== stringifyParams) {
      this._lastParams = stringifyParams
      try {
        this._listReq = await config.list('posts/', params)
        this._list = this._listReq.data
      } catch (err) {
        const res = err.response
        if (res.status === 401) {
          store.dispatch('auth/logout')
        }
      }
    }
    return this._list
    // params.filter[0].push([{mutuallyExclusive: [{field: 'posts.type', value: queryParams.pending ? }]}])
  }

  async getUsersAlreadyPostCategoryPubslished (data) {
    // /posts/assocs/users/published
    data.users.push(-1) // quando o array de ids de usuário vem vazio o axios remove o parâmetro e a validação do openAPI falha retornando 400, -1 é um id inteiro inexistente no banco
    return config.axios.get('/posts/assocs/users/published', { params: data })
  }
  async countRecipients (data) {
    return config.axios.get('/groups/assocs/listUsersbycod', { params: data })
  }
  async getStored (data) {
    if (this[`_${data}`]) {
      return this[`_${data}`]
    } else {
      return this.list()
    }
  }

  /**
   * @description Get posts associations of type group with some types
   * @param {Array} postsIds Posts to get associations
   * @param {Array} types Groups types to get associations
   */
  async getGroupsAssocs (postsIds, types) {
    const res = await config.axios.get('/posts/assocs/list', {
      params: {
        posts_ids: postsIds,
        groups_types: types
      }
    })

    return res.data
  }

  /**
   * @description Get counting of users from targets
   * @param {Object} targets Targets
   * @property {Array} targets.groups Targets of type group
   * @property {Array} targets.users Targets of type user
   */
  async countUsersFromTargets (targets) {
    const res = await config.axios.get('/posts/assocs/targets/users/count', {
      params: {
        groups: JSON.stringify(targets.groups),
        users: JSON.stringify(targets.users)
      }
    })

    return res.data.count
  }
}()
