import api from './api.config'

export default new class {
  async listClasses (matricula) {
    return (await api.axios.get(`/users/students/${matricula}/classes/list`)).data
  }
  async getCategories (userId) {
    return (await api.axios.get('/posts/categories/user/list', { params: { id: userId } })).data
  }
}()
