import api from './api.config'
import axios from 'axios'

export default new class {
  async getUrl (path) {
    const url = (await api.axios.get('personalized/',
      {
        params: { path }
      }
    )).data.url
    return url
  }

  async getComponentsMetaData (filter) {
    const res = await api.axios.get('personalized/metadata/list', {
      params: filter
    })
    return res.data
  }

  async getComponentData (url) {
    const componentData = (await axios.get(url)).data
    return componentData
  }

  async getComponentGroups (path) {
    const groups = (await api.axios.get('personalized/groups/list', {
      params: { path }
    })).data
    return groups
  }

  async saveMetadata (metadata) {
    return (await api.axios.post('personalized/metadata/save', metadata)).data
  }
}()
