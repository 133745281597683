import api from './api.config'
// import axios from 'axios'

export default new class {
  // GET
  async getUserProfiles () {
    const res = await api.axios.get('users/profile/')
    return res.data
  }

  // POST
  async saveUserProfile (UserProfileData) {
    return (await api.axios.post(`users/profile/`, UserProfileData)).data
  }

  // DELETE
  async deleteUserProfile (id) {
    return (await api.axios.delete(`users/profile/${id}`)).data
  }

  // PUT
  async updateUserProfile (UserProfileData, id) {
    return (await api.axios.put(`users/profile/${id}`, UserProfileData)).data
  }
}()
